@font-face {
  font-family: "Meme";   /*Can be any text*/
  src: local("Impact"),
    url("./fonts/impact.ttf") format("truetype");
}

@font-face {
  font-family: "Londrina";   /*Can be any text*/
  src: local("LondrinaSolid"),
    url("./fonts/LondrinaSolid-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("LondrinaSolid-Light"),
    url("./fonts/LondrinaSolid-Light.ttf") format("truetype");
}

.text-highlight {
  font-style: normal;
  font-size: larger;
  color:#fff;
  font-family: "Londrina";
  border-radius: 1em 0 1em 0;
  // text-shadow: 2px 2px 2px #000;
  text-shadow:1px 1px 0 #000,
  -1px -1px 0 #000,
  1px -1px 0 #000,
  -1px 1px 0 #000,
  0px 1px 0 #000,
  1px 0px 0 #000,
  0px -1px 0 #000,
  -1px 0px 0 #000;
}

.text-highlight-white {
  font-style: normal;
  border-radius: 1em 0 1em 0;
  text-shadow: 1px 1px 1px #fff;
  background-color: rgba(#fff, 0.8)
}

.text-meme{
  text-shadow:1px 1px 0 #000,
  -1px -1px 0 #000,
  1px -1px 0 #000,
  -1px 1px 0 #000,
  0px 1px 0 #000,
  1px 0px 0 #000,
  0px -1px 0 #000,
  -1px 0px 0 #000;
}


.text-meme-light{
  text-shadow:2px 2px 0 #fff,
  -2px -2px 0 #fff,
  2px -2px 0 #fff,
  -2px 2px 0 #fff,
  0px 2px 0 #fff,
  2px 0px 0 #fff,
  0px -2px 0 #fff,
  -2px 0px 0 #fff;
}

h2{
  word-wrap: break-word;
  font-size: 4rem;
}

.rounded-button{
  border-radius: 5px!important;
}

.rounded-button-outlined{
  color: #fff!important;
  background-color: #DAB8FF!important;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.tokenomicsBox {
  padding: 3em 1em 3em 1em;
  border-radius: 5px;
  width:auto;
}

.RoadmapBox{
  padding: 3em 1em 3em 1em;
  border-radius: 5px;
  width:auto;
  padding-left: 3rem;
  background-color: rgba(0, 0, 0, 0.08)!important;
  .MuiStepContent-root, .MuiStepConnector-line  {
    border-color: white;
  }

  .MuiSvgIcon-root{
    color: white!important;
    width: 1.5em!important;
    height: 1.5em!important;
    border: 1px solid #000;
    border-radius: 50%;
  }

  .MuiButtonBase-root{
    color: white;
  }

  
}

.main-title{
  padding-bottom: 3rem;
  word-wrap: break-word;
  font-size: 5rem!important;
}

#smoltingBg{
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 122px);
  display: flex;
  align-items: center;
  justify-content: center;

}

#smoltingBg::before{
  content: " ";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;  
  opacity: .3; 
  z-index: -1;
  // background: url("../public/svg/plushie_wassie.svg");
  // background-position: bottom;
  // background-position-x: 105%;
  // background-repeat: no-repeat;
  // background-size: contain; 
}

#bigWassieBg{
  position: relative;
  z-index: 1;
  // min-height: calc(100vh - 122px);
}

#bigWassieBg::before{
  content: " ";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;  
  opacity: .3; 
  z-index: -1;
  background: url("../public/svg/Big_Brain.svg");
  background-position: bottom;
  background-position-x: 100%;
  background-repeat: no-repeat;
  background-size: contain; 
}

#smoltingCoffee{
  position: relative;
  z-index: 1;
  // min-height: calc(100vh - 122px);
}

#smoltingCoffee::before{
  content: " ";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;  
  // opacity: .3;    
  z-index: -1;
  background: url("../public/svg/Smolting_Coffee.svg");
  background-position: bottom;
  background-position-x: 100%;
  background-repeat: no-repeat;
  background-size: contain; 
}


hr.blurred {
	height: 15px;
	border: 0;
	margin: 0;
	box-shadow: 0 15px 15px -15px #ffffff inset;
}

.spinner{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#bigWassieLine  {
  margin-top: 3em!important;
  padding-left: 0;
}

#socials{
  padding-left: 0;
}

#BaseLine{
  padding: 1rem;
}

// @media (min-width: 1536px) {
//   #BaseLine  {
//     margin-left: 130px!important;
//   }
//   #bigWassieLine {
//     margin-left: 75px!important;
//   }
// }

.socialIcon {
  height: 85px!important;
  width: 85px!important;
}

.title-section{
  font-size: 4.5rem!important;
}